<template>
	<div class="lobarreview">
		<div class="padd20 borbut20" style="position: relative;">
            <div class="financiallistFrome active">
                <div class="topSearchSou">
                    <div class="searchdan">
                        <div class="title">端口ID：</div>
                        <el-select v-model="adv_main_id" placeholder="请选择端口" clearable>
                            <el-option v-for="(item,key) in portList" :key="key" :label="item.advertiser_full_name" :value="item.id"></el-option>
                        </el-select>
                    </div>
					<div class="searchdan">
                        <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                		<el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
                    </div>
                </div>
            </div>
            <!-- <div class="RightBotton">
                
            </div> -->
        </div>
		<div class="finanOrderStatus padd20 borbut20 ">
            <div class="finantitle">订单状态：</div>
            <div class="statusList">
                <div class="dan" v-for="(item,key) in OrderStatus" :key="key" :class="status == item.value ? 'active' : ''" @click="onOrderStatus(item)">{{item.label}}</div>
            </div>
        </div>
        <div class="tableData padd20" style="position: relative;">
			<div class="duoshao" style="position: absolute;right:20px">
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
			<el-tabs v-model="temp_class" type="card" @tab-click="handleClick">
				<el-tab-pane label="广告计划" name="ReviewTemp">
					<ReviewTempTable ref="ReviewTempTable" :ReviewTempList='ReviewTempList' @toAttachment='toAttachment'></ReviewTempTable>
				</el-tab-pane>
				<el-tab-pane label="落地页" name="LandingPageTemp">
					<LandingPageTempTable ref="LandingPageTempTable" :LandingPageTempList='LandingPageTempList' @toAttachment='toAttachment'></LandingPageTempTable>
				</el-tab-pane>
			</el-tabs>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
		</div>
    <AttachmDialog ref="AttachmDialog"></AttachmDialog>
	</div>
</template>

<script>
import AttachmDialog from './attachmDialog.vue'//广告计划
import ReviewTempTable from './ReviewTempTable.vue'//广告计划
import LandingPageTempTable from './LandingPageTempTable.vue'//落地页
export default {
	data () {
		return {
			paramData:{},//表格参数
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页


			adv_main_id:'',//端口id
			temp_class: 'ReviewTemp',//
			status:'1',//订单状态
			
            portList:[],//端口列表
			ReviewTempList:[],//广告计划列表
			LandingPageTempList:[],//落地页列表
			OrderStatus:[//订单状态列表
				{
					value: '',
                    label: '全部'
				},
                {
                    value: '1',
                    label: '未处理'
                }, {
                    value: '2',
                    label: '处理中'
                },{
                    value: '3',
                    label: '处理完成'
                },
            ],
		}
	},

	components:{
        AttachmDialog,
		ReviewTempTable,
		LandingPageTempTable,
	},

	mounted(){
		this.ongetAdvertiserList()
		this.oncustomRepairOrderList()
	},

	methods: {
		//查看附件
        toAttachment(data,templateName){
            this.$refs.AttachmDialog.ondialogVisible(data,templateName)
        },
		//订单状态点击
        onOrderStatus(item){
            this.status = item.value
            this.oncustomRepairOrderList()
        },
		//搜索
		onSearch(){
			this.paramData = {
                adv_main_id:this.adv_main_id,
            }
			this.oncustomRepairOrderList()
		},
		//选项卡点击切换
		handleClick(tab, event) {
            this.paramPage = 1
			this.oncustomRepairOrderList()
		},
		//人工工单复审的数据
		oncustomRepairOrderList(){
			this.ReviewTempList = []
			this.LandingPageTempList = []
            var param = this.paramData
			param.status = this.status, //订单状态
			param.temp_class = this.temp_class, //分类
			param.per_page = this.per_page,//每页展示条数
			param.page = this.paramPage//分页
            this.$service.get(this.$api.customRepairOrderList,param, (res)=> {
				if(res.code == '200'){
					this.temp_class == 'ReviewTemp' ? this.ReviewTempList = res.data.data : this.temp_class == 'LandingPageTemp' ? this.LandingPageTempList = res.data.data : ''
                    this.lvtotal = res.data.total
				}
			})
		},
		//端口列表
        ongetAdvertiserList(){
            this.$service.get(this.$api.getAdvertiserList,{},(res)=> {
				if(res.code == '200'){
                    this.portList = res.data.data
				}
			})
        },
		// 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.oncustomRepairOrderList()
        },
	}
}
</script>

<style lang="scss" scoped>
.login{
	
}
</style>
