<template>
<div>
    <el-table ref="filterTable" :data="ReviewTempList" style="width: 100%">
        <el-table-column prop="json_param.company_name" label="分类">
            <template slot-scope="scope">
                {{scope.row.temp_class == 'ReviewTemp' ? '广告计划' : scope.row.temp_class == 'LandingPageTemp' ? '落地页' : '--'}}
            </template>
        </el-table-column>
        <el-table-column prop="json_param.company_name" label="广告主公司名称"></el-table-column>
        <el-table-column prop="json_param.advertiser_account_id" label="广告主ID"></el-table-column>
        <el-table-column prop="json_param.advertising_program_id" label="广告计划ID"></el-table-column>
        <el-table-column prop="json_param.first_refuse_to_reason" label="一审拒绝理由"></el-table-column>
        <el-table-column prop="json_param.cotinue_refuse_to_reason" label="复审申诉理由"></el-table-column>
        <el-table-column prop="created_at" label="提交时间"></el-table-column>
        <el-table-column prop="json_param.company_name" label="工单处理状态">
            <template slot-scope="scope">
                <el-link :type="scope.row.status == '1' ? 'warning' : scope.row.status == '2' ? 'primary' : 'success'" >{{scope.row.status == '1' ? '未处理' : scope.row.status == '2' ? '处理中' : '处理完成'}}</el-link>
            </template>
        </el-table-column>
        <el-table-column prop="advertiser.advertiser_full_name" label="端口名称"></el-table-column>
        <el-table-column prop="json_param.company_name" label="操作">
            <template slot-scope="scope">
                <el-button type="primary" @click="toAttachment(scope.row,'广告计划')">查看详情</el-button>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
export default {
    props:{
        ReviewTempList: {
            type: Array,
            default: []
        },
    },
    components:{
    },

    data() {
        return {
            
        };
    },
    mounted() {
    },

    methods: {
        //查看附件
        toAttachment(data,templateName){
            this.$emit('toAttachment',data,templateName)
        },
    },


    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>