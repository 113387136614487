<template>
    <el-dialog title="查看附件" :visible.sync="dialogVisible" width="850px"  class="class-dialog" :before-close='onCancel' modal-append-to-body>
        <div class="dialog-inside AttachmentDialog" v-if="dialogVisible">
            <div class="json-param">
                <div class="param-top">
                    <p>端口ID：{{AdvertiserData.advertiser_name}}</p>
                    <p>广告主公司名称：{{orderData.json_param.company_name}}</p>
                </div>
                <div class="param-bottom">
                    <p>广告主ID：{{orderData.json_param.advertiser_account_id}}</p>
                    <p>广告计划ID：{{orderData.json_param.advertising_program_id}}</p>
                    <p><span>一审拒绝理由：</span>{{orderData.json_param.first_refuse_to_reason}}</p>
                    <p><span>复审申诉理由：</span>{{orderData.json_param.cotinue_refuse_to_reason}}</p>
                </div>
            </div>
            <div class="editor">
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>工单ID</div>
                    <div class="sunbox-content">
                        <el-input v-model="ticketId" placeholder="请输入工单ID"></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>工单状态</div>
                    <div class="sunbox-content">
                        <el-input v-model="ticketStatusName" placeholder="请输入工单状态"></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>工单处理状态</div>
                    <div class="sunbox-content">
                        <el-radio-group v-model="orderData.status">
                            <el-radio label="1">未处理</el-radio>
                            <el-radio label="2">处理中</el-radio>
                            <el-radio label="3">处理完成</el-radio>
                        </el-radio-group>
                        <!-- <el-input v-model="ticketStatusName" placeholder="请输入工单状态"></el-input> -->
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>业务方</div>
                    <div class="sunbox-content">
                        <el-input v-model="ticketApp" placeholder="请输入业务方"></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>工单分类</div>
                    <div class="sunbox-content">
                        <el-input v-model="templateName" placeholder="请输入工单分类" :disabled="true"></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>创建时间</div>
                    <div class="sunbox-content">
                        <el-date-picker v-model="createTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>更新时间</div>
                    <div class="sunbox-content">
                        <el-date-picker v-model="updateTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>广告主信息</div>
                    <div class="sunbox-content">
                        <el-input v-model="advName" placeholder="请输入广告主信息"></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>代理商账户ID</div>
                    <div class="sunbox-content">
                        <el-input v-model="agentId" placeholder="请输入代理商账户ID"></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>广告主账户ID</div>
                    <div class="sunbox-content">
                        <el-input v-model="advId" placeholder="请输入广告主账户ID"></el-input>
                    </div>
                </div>
                <div class="sunbox" style="margin-bottom:80px">
                    <div class="sunbox-text"><span class="text-xing">*</span>富文本</div>
                    <div class="sunbox-content">
					    <Tinymce ref="Tinymce" @getTreeData='onad_abnormal_point' :contentData='repair_order_desc' pathpage="moren" uniqueId='uniqueId'></Tinymce>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
import Tinymce from '../ticketCreate/tinymce.vue'
export default {
    data() {
        return {
            dialogVisible:false,
            orderData:{},//详情数据
            AdvertiserData:{},//获取当前端口数据
            repair_order_desc:'',//富文本接收参数

            repair_order_id:'',
            ticketId: '',
            ticketStatusName: '',
            ticketApp: '',
            templateName: '',
            createTime: '',
            updateTime: '',
            advName: '',
            agentId: '',
            advId: '',
            repair_order_type:'2',
            
        };
    },
    components:{
        Tinymce,
    },
    mounted() {
    },

    methods: {
        //富文本接收参数
        onad_abnormal_point(data){
            this.repair_order_desc = data
        },
        // 打开弹窗
        ondialogVisible(data,templateName){
            this.templateName = templateName
            console.log(templateName)
            this.oncustomRepairOrderRes(data.id)
        },
        //调取详情
        oncustomRepairOrderRes(id){
            var param = {
                custom_repair_order_id:id
            }
            this.$service.get(this.$api.customRepairOrderRes,param, (res)=> {
                if(res.code == '200'){
                    this.orderData = res.data
                    this.status = res.data.status
                    this.onParamOrder(res.data.repair_order)
                    this.ongetAdvertiserList(res.data)
                    this.dialogVisible = true //掉完接口打开弹窗
                }
            })
        },
        //端口列表
        ongetAdvertiserList(orderdata){
            this.$service.get(this.$api.getAdvertiserList,{},(res)=> {
				if(res.code == '200'){
                    res.data.data.forEach((v,k) => {
                        if(orderdata.json_param.adv_main_id == v.id){
                            this.AdvertiserData = v
                        }
                    });
				}
			})
        },
        // 默认参数
        onParamOrder(data){
            if(data== null) {return}
            this.repair_order_id = data.id
            this.ticketId = data.ticketId,
            this.ticketStatusName = data.ticketStatusName,
            this.ticketApp = data.ticketApp,
            this.templateName = data.templateName,
            this.createTime = data.createTime,
            this.updateTime = data.updateTime,
            this.advName = data.advName,
            this.agentId = data.agentId,
            this.advId = data.advId,
            this.repair_order_type = data.repair_order_type,
            this.repair_order_desc = data.repair_order_desc
        },
        // 提交
        onSubmit(){
            var param = {
                repair_order_id: this.repair_order_id,
                custom_repair_order_id: this.orderData.id,
                status: this.orderData.status,
                wx_id: this.orderData.user_msg.server_wechat.id,
                adv_main_id: this.orderData.json_param.adv_main_id,
                ticketId: this.ticketId,
                ticketStatusName: this.ticketStatusName,
                ticketApp: this.ticketApp,
                templateName: this.templateName,
                createTime: this.createTime,
                updateTime: this.updateTime,
                advName: this.advName,
                agentId: this.agentId,
                advId: this.advId,
                repair_order_type: this.repair_order_type,
                repair_order_desc: this.repair_order_desc
            }
            this.$service.post(this.$api.createCustomRepairOrder,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message: '修改成功',type: 'success'});
                    this.onCancel()
                }
            })

        },
        //取消
        onCancel(){
            this.dialogVisible = false
            this.orderData = {}
            this.AdvertiserData={}
            this.repair_order_desc = ''
            this.repair_order_id = '',
            this.ticketId =  '',
            this.ticketStatusName =  '',
            this.ticketApp =  '',
            this.templateName =  '',
            this.createTime =  '',
            this.updateTime =  '',
            this.advName =  '',
            this.agentId =  '',
            this.advId =  '',
            this.repair_order_type = '2'
        }
    },


    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.AttachmentDialog{
    .json-param{
        border: 1px solid #ededed;
        margin-bottom: 20px;
        .param-top{
            padding: 20px;
            background: #ededed;
            display: flex;
            justify-content: space-between;
        }
        .param-bottom{
            display: flex;
            flex-wrap: wrap;
            p{
                padding: 10px 20px;
                span{
                    color: #f00;
                }
            }
        }
    }
}
</style>